.home {

    width: 100vw;
    margin-top: 140px;
}

.home-wrapper-1 {
    position: relative;
    width: 100%;
    height: calc(100vh - 115px);
    overflow-x: hidden;
    display: flex;

}

.home-pink-background {
    position: absolute;
    top: 0;
    right: 0;
    width: 35vw;
    height: 100%;
    background-color: #F2EDE6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.home-pink-img {
    width: 12vw;
    height: 40vh;
    border-radius: 15px 15px 0 0;
}

.home-pink-img img {
    width: 100%;
    height: 100%;
}

.home-shop-now {
    background: #798F7D 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #fff;
    margin-top: 20px;
    width: 12vw;
    height: 7vh;
    font-size: 1.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
}

.home-first-discover {
    display: flex;
    flex-direction: column;
    // margin-left: 320px;
    position: absolute;
    left: 20vw;
}

.home-discover {
    font-size: 4.5vw;
    font-weight: 300;
    color: #292929;
    z-index: 6;
    line-height: 1;
    font-family: 'RollgatesLuxury';
}

.home-discover p {
    margin-left: 60px;
    font-family: 'RollgatesLuxury';
}

.home-modern {
    margin-left: 3vw;
    margin-top: 3vw;
    font-size: 1.2vw;
    width: 20vw;
}

.home-middle-img {
    width: 27vw;
    margin-top: 30px;
    z-index: 5;
    height: 70vh;
    position: absolute;
    top: 0;
    left: 43.5%;
}

.home-middle-img img {
    height: 100%;
    width: 100%;
    // object-fit: contain;
}

.home-first-img {
    margin-top: 50px;

    width: 17vw;
    height: 30vh;
    margin-left: 3vw;
}

.home-first-img img {
    width: 100%;
    height: 100%;
    // object-fit: contain;
}

.home-wrapper-2 {
    background-color: #FAF9F7;
    height: 100vh;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.home-flower-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 450px;
    height: 450px;
}

.home-flower-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.home-featured {
    font-size: 60px;
    color: #292929;
    font-weight: 300;
    font-family: 'RollgatesLuxury';
}

.home-products-imgs {
    display: flex;
}

.home-rings {
    // width: 200px;
    margin-top: 20px;
    align-self: flex-start;
    margin-left: 30px;
}

.home-product-img-1 {
    width: 170px;
    height: 200px;
    margin-top: 30px;
    // margin-right: 25px;
}

.home-product-img-2 {
    width: 200px;
    height: 300px;
    margin-top: 60px;
    margin-right: 50px;
}

.home-product-img-3 {
    width: 200px;
    height: 300px;
    margin-top: 90px;
}

.home-product-img-1 img,
.home-product-img-2 img,
.home-product-img-3 img {
    width: 100%;
    height: 100%;
    object-fit: inherit;
}

#brand-see-all {
    align-self: center;
}


.home-product-1 {
    display: flex;
    flex-direction: column;
    // margin-right: 30px;
    align-items: center;
    justify-content: center;
}

.home-see-all {
    border: 1px solid #292929;
    border-radius: 40px;
    padding: .5rem 1rem;
    cursor: pointer;
    width: fit-content;
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    text-decoration: none;
    color: #292929;
    align-self: flex-start;
    margin-left: 30px;
}

#brand-see-all {
    border: 1px solid #292929;
    border-radius: 15px;
    padding: 1rem;
    cursor: pointer;
    width: -moz-fit-content;
    width: 120px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    color: #292929;
}

.home-see-all-p {
    font-size: 12px;
}

.home-see-all-img {
    width: 30px;
    height: 12px;
    object-fit: contain;
}

.home-wrapper3 {
    position: relative;
}

.home-wrapper-3 {
    width: 100%;
    // height: 100vh;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.home-flower-img2 {
    position: absolute;
    top: -15%;
    right: 0;
}

.home-new-arrival {
    font-size: 60px;
    font-weight: 400;
    font-family: 'RollgatesLuxury';
}

.home-new-arrival-back-front-imgs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.home-back-front-imgs {
    display: flex;
    gap: 15px;
}

.home-back-img,
.home-front-img {
    width: 70px;
    height: 50px;
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.home-back-img img,
.home-front-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.home-front-img .swiper-front {
    display: block;
    transition: opacity 0.5s ease-in-out;
    transform: rotate(180deg);
}

.home-back-img .swiper-back {
    display: block;
    transition: opacity 0.5s ease-in-out;

}

.home-front-img .swiper-front-hover {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

}

.home-back-img .swiper-back-hover {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    transform: rotate(180deg);

}

.home-front-img:hover .swiper-front {
    opacity: 0;
}

.home-back-img:hover .swiper-back {
    opacity: 0;
}

.home-front-img:hover .swiper-front-hover {
    opacity: 1;
}

.home-back-img:hover .swiper-back-hover {
    opacity: 1;
}

.home-wrapper-4 {
    background-color: #FAF9F7;
    // height: 100vh;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

}

.home-flower-img3 {
    position: absolute;
    top: -9%;
    left: 0;
    width: 250px;
    height: 250px;
}

.home-flower-img3 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.home-wrapper4-cont {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 200px;
}

.home-social {
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-family: 'RollgatesLuxury';
}

.home-insta {

    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    color: #B4804D;
    font-family: 'RollgatesLuxury';
}

.home-posts {
    display: flex;
    align-items: center;
}

.home-post-img {
    width: 180px;
    height: 250px;

    video {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.home-post-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.home-left-img {
    transform: rotate(180deg);
    cursor: pointer;
}

.home-right-img {

    cursor: pointer;
}







.home-products-swiper {
    display: flex;
    gap: 30px;
    margin-bottom: 15px;
    justify-content: center;
}

.swiper-pagination2 {
    display: none;
}

.home-products-swiper2 {
    display: flex;
    gap: 30px;
    // flex-wrap: wrap;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;
}

.home-products-swiper3 {
    display: flex;
    gap: 30px;
    // flex-wrap: wrap;
    margin-bottom: 15px;

}

.home-swiper-img-1 {
    width: 150px;
    height: 150px;
    position: relative;
}

.home-swiper-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.home-abs-discount {
    position: absolute;
    left: 5%;
    top: 5%;
    padding: .25rem .5rem;
    background: #7F9C89 0% 0% no-repeat padding-box;
    color: #fff;
    font: normal normal normal 13px/16px Josefin Sans;
    z-index: 1;
}

.home-golden {
    font: normal normal 600 18px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bold;
    text-align: center;
}

.home-price {
    font: normal normal normal 14px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 15px;
}

.home-menu {
    width: 270px;
    height: 230px;
    background: #FAF9F7 0% 0% no-repeat padding-box;
    position: relative;
    // border: 2px solid #B4804D;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.home-directions-swiper {
    display: flex;
    align-items: center;
}

.home-menu2 {
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px solid #FAF9F7;
    background: #FAF9F7 0% 0% no-repeat padding-box;
    position: relative;
    margin-bottom: 10px;

    &:hover {
        border: 2px solid #B4804D;
        border-radius: 3px;

        .fav-love,
        .fav-love2,
        .fav-add-col {
            display: block;
        }

        .home-golden,
        .home-price {
            display: none;
        }

    }
}

.home-pag {

    .swiper-pagination-bullet-active {

        background: #F1C376;
    }
}

.home-swipers {
    width: 100%;
    position: relative;
}

#home-swipers10 {
    width: 76%;
    position: relative;
}

.nav-logout {
    cursor: pointer;
}

// .swiper-slide {
//     text-align: center;
//     font-size: 18px;
//     background: #fff;
//     height: calc((100% - 30px) / 2) !important;

//     /* Center slide text vertically */
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

.instagram-embed-wrapper {
    position: relative;
    width: 100%;
    max-width: 340px;
    height: 400px;
    /* Adjust this value to set the desired height */
    overflow: hidden;
}

.instagram-embed-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.test-insta .instagram-media {
    min-width: auto !important;
}

// .test-insta #instagram-embed-1 .Header .HeaderCta {
//     display: none !important;
// }

.message {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    z-index: 1000;
}

.message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}
#home-product-change-size{
    width: 220px;
    height: 220px;
    img{
        width: 100%;
        height: 100%;
        // object-fit: contain;
    }
}
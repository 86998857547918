.register-register-please{
    display: flex;
    flex-direction: column;
}
.register-register-bird{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
.register-bird{
    width: 100px;
height: 110px;
object-fit: contain;
}
.register-heart1{
    top: 40%;
    position: absolute;
    left: 5%;
    width: 230px;
}
.register-heart2{
    bottom: 30%;
    position: absolute;
    right: 15%;
}
.register-privacy {
    font: normal normal 600 35px Josefin Sans;
    color: #292929;
   
    align-self: center;
    position: relative;
    
}
.details {
    width: 100vw;
    margin-top: 140px;
    margin-bottom: 200px;
}

.details-wrapper-1 {
    position: relative;
    width: 100%;
    // height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
}

.details-grey-line {
    height: 60px;
    background: #FAF9F7 0% 0% no-repeat padding-box;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 150px;
}

.details-shop {
    font-size: 15px;
    color: #929292;
    margin-right: 8px;
    cursor: pointer;
    text-transform: capitalize;
}

.details-left {
    // margin-left: 150px;
    // max-width: 600px;
}

.detials-ring-img {
    width: 550px;
    height: 420px;
    margin-top: 20px;
    position: relative;
}

.detials-ring-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.details-ring-imgs {
    display: flex;
    align-items: center;
    margin-top: 20px;
    max-width: 500px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.detials-ring-collection {
    width: 130px;
    height: 100px;
}

.detials-ring-collection img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.details-left-arrow {
    transform: rotate(180deg);
    cursor: pointer;
    width: 25px;
    height: 25px;
}

.details-left-arrow img,
.details-right-arrow img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.details-right-arrow {
    cursor: pointer;
    width: 25px;
    height: 25px;
}

.details-right {
    max-width: 50%;
    width: 550px;
}


.capitalize-first-letter {
    text-transform: capitalize;
}

.details-no {
    text-align: center;
    font-size: 25px;
    margin-top: 50px;
}

.details-rebel {
    text-align: left;
    font: 20px;
    letter-spacing: 7.5px;
    color: #B4804D;
    text-transform: uppercase;
    margin-top: 20px;
    cursor: pointer;
}

.details-left-right {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem;
}

.details-golden {
    font: normal normal 600 40px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 20px;
}

.details-price {
    font: normal normal normal 25px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 20px;
}

.details-color {
    font: normal normal bold 18px Josefin Sans;
    color: #929292;
    margin-top: 20px;
}

.details-color-circles {
    display: flex;
    margin-top: 20px;
    gap: 20px;
    padding-bottom: 10px;
    width: auto;
    overflow-x: auto;

}

.details-size-number {
    padding: .25rem;
    min-width: max-content;
    cursor: pointer;
}

.details-color-circle-1 {
    width: 40px;
    height: 40px;
    background: #FAF9F7 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 28px;
    cursor: pointer;
}

.details-color-circle-2 {
    width: 40px;
    height: 40px;
    background: #F5E0CF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 28px;


}

.details-plus5 {
    cursor: pointer;
}

.details-color-circle-3 {
    width: 40px;
    height: 40px;
    background: #E9CFA3 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 28px;
}

.details-size {
    font: normal normal bold 18px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    margin-top: 20px;
}

.details-sizes {
    display: flex;
    margin-top: 20px;
}

.detials-size-number {
    padding: .7rem 1.25rem;
    background-color: #FAF9F7;
    font: normal normal 600 14px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    margin-right: 10px;
    cursor: pointer;

}

.details-size-number.selected {
    background-color: #292929;
    color: white;
    
}

.details-link {
    position: absolute;
    top: 0%;
    // right: 50%;
    left: 0;
    right: 0;
    width: fit-content;
    padding: 0.25rem .5rem;
    border-radius: 5px;
    background-color: #292929;
    color: white;
    margin-left: auto;
    margin-right: auto;
}

.selected {
    background-color: #292929;
    color: white;
}

.details-quantity-add {
    display: flex;
    align-items: center;
    margin-top: 50px;
    justify-content: space-between;
}

.details-minus {
    height: 1px;
    width: 20px;
    background-color: #292929;
    margin: 0 30px;
    cursor: pointer;
}

.details-plus {
    position: relative;
    width: 20px;
    height: 20px;
    /* Change the background color as needed */
    cursor: pointer;
}

.details-plus::before,
.details-plus::after {
    content: '';
    position: absolute;
    background-color: #292929;
    /* Change the color of the plus sign */
}

/* Horizontal line */
.details-plus::before {
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    /* Adjust the height of the line */
}

/* Vertical line */
.details-plus::after {
    left: 45%;
    top: 0;
    width: 1px;
    /* Adjust the width of the line */
    height: 100%;
}

.details-number {
    // margin: 0 30px;
    width: 30px;
    font-size: 20px;
}

.details-plus {

    margin: 0 30px;
}

.details-left-swiper {
    display: none;
}

.details-add {
    background: #292929 0% 0% no-repeat padding-box;
    border-radius: 3px;
    padding: 1rem 2rem;
    color: #fff;
    width: 50%;
    cursor: pointer;
    text-align: center;
}

#details-add-token {
    padding: 1rem;
    width: auto;
}

.details-center {
    // justify-content: center !important;
    gap: 50px;
}

.details-buy-now {
    background: #798F7D 0% 0% no-repeat padding-box;
    border-radius: 3px;
    padding: 1rem;
    margin-top: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.details-wrapper-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    position: relative;
    padding: 1rem 2rem;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;

}

.details-description-details {
    display: flex;
    gap: 30px;
    margin-bottom: 50px;
}

.details-description {
    font: normal normal bold 15px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    text-decoration: none;
    cursor: pointer;
}

.details-details {
    font: normal normal bold 15px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    text-decoration: none;
    cursor: pointer;
}

.active {
    color: black;
    font: normal normal bold 15px Josefin Sans;
    letter-spacing: 0px;
    // color: #929292;
    text-decoration: none;
    cursor: pointer;
    /* Set the color to black for active links */
}

.details-match {
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;

    align-self: flex-start;
}

.details-free {
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;

}

.detials-free-img {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
    align-self: flex-start;
}

.detials-shippingimg {
    width: 15px;
    height: 25px;

}

.detials-shippingimg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.details-new-arrival-back-front-imgs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 150px;
}

.details-Details {
    width: 100%;
}

.details-ul {}

.details-li {
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    margin-top: 15px;
    line-height: 1.5;
}

.details-li2 {
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
}

.details-material {
    font: normal normal bold 20px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 50px;
}

.details-14 {
    font: normal normal bold 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.details-menu {
    width: 375px;
    height: 300px;
    background: #FAF9F7 0% 0% no-repeat padding-box;
    position: relative;
    // border: 2px solid #B4804D;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.details-menu2 {
    width: 120px;
    height: 120px;

    position: relative;
    // border: 2px solid #B4804D;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.details-swiper-img-1 {
    width: 80%;
    height: 50%;
    margin-top: 50px;
    position: relative;
}

#home-swiper-img-2,
#home-swiper-img-1 {
    width: 150px;
    height: 150px;
    margin-top: 0;
}

.details-swiper-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.details-swiper-img-2 {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.details-swiper-img-2 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal-rebel-img {
    width: 200px;
    height: 100px;
}

.modal-rebel-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal-header {
    border-bottom: none !important;
}

.modal-kite-img {
    // background-color: #00000029;
    width: 350px;
    height: 350px;
}

.modal-kite-img img {
    mix-blend-mode: darken;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal-body-cont {
    display: flex;

    width: 100%;
    padding: 3rem;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;

}

.modal-explore {
    background: #292929 0% 0% no-repeat padding-box;
    padding: .5rem 1rem;
    color: #fff;
    margin-top: auto;
    width: fit-content;
    display: flex;
    font-size: 18px;
    font-weight: 300;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
}

.modal-details-flex {
    display: flex;
    flex-direction: column;
}

.modal-right-arrow {
    width: 35px;
    height: 35px;
    margin-left: 30px;
}

.modal-right-arrow img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal-brought {
    color: #929292;
    margin-top: 30px;
    font-size: 15px;

    p {
        margin: 0;
    }
}

.modal-swiper {
    width: 50%;
    position: relative;
}

.modal-lizzard {
    position: absolute;
    top: -50px;
    right: -10px;
    width: 150px;
    height: 130px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.swiper-wrapper {}

.modal-golden {
    text-align: center;
    font: normal normal 600 25px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
}

.modal-close {
    margin-left: auto;
    display: flex;
    width: 50px;
    height: 50px;
    cursor: pointer;
    object-fit: contain;
}

.detailsModal-shopping-bag {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 10px;
}

.detailsModal-body-cont {
    display: flex;

    width: 100%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2rem;
    flex-direction: column;
    height: 90%;
    border-top: 1px solid #F5F5F5;
}

.detailsModal-you {
    font: normal normal normal 13px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 20px;
}

.detailsModal-show {
    margin-top: 20px;
    background: #798F7D 0% 0% no-repeat padding-box;
    border-radius: 3px;
    width: 100%;
    text-align: center;
    color: #fff;
    padding: .75rem;
    cursor: pointer;
}

.detailsModal-add {
    text-decoration: underline;
    font: normal normal normal 13px Josefin Sans;
    letter-spacing: 0px;
    cursor: pointer;
    color: #929292;
}

.detailsModal-add2 {
    font: normal normal normal 13px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
}

.detailsModal-shipping {
    font: normal normal normal 13px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
}

.detailsModal-line {
    border: 3px solid #292929;
    border-radius: 10px;
    width: 90%;
    margin-top: 20px;
    position: relative;
}

.detailsModal-line2 {
    border: 1px solid #F2EDE6;
    border-radius: 10px;
    width: 100%;
    margin-top: 20px;
    position: relative;
}

.detailsModal-bird {
    position: absolute;
    top: -65px;
    left: 5%;
    width: 70px;
    height: 70px;
    object-fit: contain;
}

#details-shop {
    cursor: pointer;
}

.detailsModal-plus-minus {
    display: flex;
    gap: 25px;

    border: 1px solid #F5F5F5;
    border-radius: 3px;
    padding: .5rem 1.25rem;
    margin-right: 20px;
}

.detailsModal-remove {
    text-decoration: underline;
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    cursor: pointer;
}

.detailsModal-add-note-section {
    width: 100%;
    margin-top: auto;
    z-index: 99999999999999;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 300px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2rem;
}

.detailsModal-save {
    background: #292929 0% 0% no-repeat padding-box;
    border-radius: 3px;
    padding: .75rem 2rem;
    text-align: center;
    margin-top: 20px;
    color: #fff;
    width: fit-content;
    cursor: pointer;
}

.details-star {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
}

.kite {
    .swiper-pagination-bullet-active {

        background: #292929;
    }
}

.modal-kite-golden {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details-love {

    position: absolute;
    top: 3%;
    right: 5%;
    width: 40px !important;
    height: 40px !important;
    object-fit: contain;
    cursor: pointer;
}

.details-love2 {

    position: absolute;
    top: 15%;
    right: 5%;
    width: 40px !important;
    height: 40px !important;
    object-fit: contain;
    cursor: pointer;
}
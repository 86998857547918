.ex-wrapper {
    display: flex;
    max-width: 1200px;
    width: 100%;
}

.ex-banner {
    width: 100%;
    height: 500px;
    margin-top: 50px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}

.ex-banner-abs {
    position: absolute;
    top: 10%;
    right: 23%;
    border-radius: 24px;
    opacity: 1;
   background-color: rgba(255, 255, 255, 0.9);
    width: 500px;
    height: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.ex-get {
    text-align: center;
    font-size: 40px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 25px;
}

.ex-off {
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #292929;
}

.ex-sub {
    text-align: center;
    font-size: 13px;
    color: #292929;
}

.ex-email {
    font-size: 15px;
    color: #FFFFFF;
    text-transform: uppercase;
    background: #798F7D 0% 0% no-repeat padding-box;
    border-radius: 3px;
    padding: 1rem;
    width: 80%;
    text-align: center;
    cursor: pointer;
}

.ex-input {
    background-color: rgba(0, 0, 0, 0.4);
    // background: none;
    opacity: 1;
    backdrop-filter: blur(530px);
    -webkit-backdrop-filter: blur(30px);
    width: 80%;
    margin-bottom: 10px;
    border: 0;
    padding: 1rem;
    outline: none;
    &::placeholder{
        font-style: 13px;
        letter-spacing: 0px;
        color: #fff;
    }
}

.ex-un {
    text-align: center;
    margin-top: 20px;
    font-style: 13px;
    letter-spacing: 0px;
    color: #292929;

   .ex-link{
    color: #292929;

   }
}
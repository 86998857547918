$width: 662px;


@media (max-width: #{$width}) {
   .contact-left-right{
    flex-direction: column-reverse;
    padding: 2rem;
    margin-top: 10px;
   }
   .contact-left,.contact-right{
    width: 100%;
   }
   .contact-left{
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top:130px;
   }
  #contact-details,.contact-grey-line2{
      display: none;
   }
   .contact-details2{
      display: block;
      text-align: center;
   }
   #contact-textarea{
      padding-bottom: 10rem;
   }
   .contact-send{
      text-transform: uppercase;
   }
   .contact-details {
      font-size: 16px;
   }
   #contact-contact{
      text-transform: uppercase;
      margin-top: 50px;
   }
   .contact-wrapper{
      padding: 0;
   }
}
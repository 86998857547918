.contact-wrapper {
    position: relative;
    width: 100%;
    // height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
}

.contact-contact-cont {
    position: relative;
    display: flex;
}

.contact-bird-img {
    position: absolute;
    top: -130%;
    right: -8%;
}

.contact-left-right {
    display: flex;
    margin-top: 100px;
    justify-content: center;
}

.contact-details {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    text-transform: uppercase;
}

.contact-left {
    width: 50%;
}

.contact-right {
    width: 50%;
}

.contact-grey-line {
    width: 95%;
    height: 1px;
    background-color: #CECBC8;
    margin-top: 10px;
}

.contact-grey-line2 {
    width: 100%;
    height: 1px;
    background-color: #CECBC8;
    margin-top: 10px;
}

.contact-logo {
    width: 100px;
    height: 80px;
    object-fit: contain;
    margin-top: 30px;
}

.contact-boston {
    font: normal normal normal 20px Josefin Sans;
    color: #292929;
    margin-top: 20px;
}

.contact-google {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #292929;
    border-radius: 40px;
    width: fit-content;
    margin-top: 20px;
    padding: 1rem 2rem;
}

.contact-input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    margin-top: 30px;
    width: 100%;
    padding: .75rem;

    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #929292;
    }
}

.contact-input2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    margin-top: 10px;
    width: 100%;
    padding: .75rem;

    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #929292;
    }
}

.contact-send {
    background: #798F7D 0% 0% no-repeat padding-box;
    border-radius: 3px;
    padding: 1rem;
    color: #fff;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
}

.contact-flower-img {
    position: absolute;
    bottom: 0;
    width: 350px;
    height: 350px;
    left: 0;
}

.contact-flower-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.return-request-dropdown {
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;

    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #929292;
    }
}

.return-request-upload {
    border: 1px solid #CECBC8;
    border-radius: 3px;
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    flex-direction: column;
    cursor: pointer;
}

.return-request-text {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    margin-top: 5px;
}

.return-request-img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.return-request-file-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.return-request-file-name {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 10px;
}

.progress-bar {
    width: 100%;
    background-color: #FEF9F5 !important;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 10px;
}

.progress-bar-fill {
    height: 5px;
    background-color: #B4804D;
    transition: width 0.2s ease-in-out;
}
.contact-details2,.return-rquest-contact-details{
    display: none;
}
.contact-error-message{
    color: red;
    font-size: 15px;
    margin-top: 15px;
}
.shop-filter {
    font: normal normal bold 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.shop-wrapper {
    position: relative;
    width: 100%;
    // height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;

    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 0 2rem;
}

.shop-short {
    font: normal normal bold 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;

    // margin-bottom: 15px;
    margin-right: 10px;
}

.p-inputswitch {
    width: 2rem;
    height: 1rem;
}

.p-inputswitch .p-inputswitch-slider:before {
    background: #ffffff;
    width: .75rem;
    height: .75rem;
    left: 0.25rem;
    margin-top: -0.4rem;
    border-radius: 50%;
    transition-duration: 0.2s;
}

.p-inputswitch.p-highlight .p-inputswitch-slider:before {
    background: #ffffff;
    transform: translateX(.75rem);
}

.shop-grey-line {
    width: 100%;
    height: 1px;
    border: 1px solid #ECE0D4;
    margin-bottom: 20px;
    margin-top: 20px;
}

.p-slider-horizontal .p-slider-handle {
    top: 50%;
    transform: translate(-50%, -50%);
}

.shop-wrapper-left {
    max-width: 250px;
    min-width: 250px;
    width: 250px;
}

.shop-xs {
    background: #FAF9F7 0% 0% no-repeat padding-box;
    border-radius: 3px;
    // width: 50px;
    // height: 35px;
    margin-right: 10px;
    font: normal normal 600 14px Josefin Sans;
    letter-spacing: 0px;
    margin-bottom: 10px;
    color: #929292;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
}

.shop-wrapper-left-right {
    display: flex;
    justify-content: center;
    gap: 50px;
}

.shop-main-img {
    width: 100%;
    // height: 300px;
    object-fit: fill;
    // margin-bottom: 20px;
}

.shop-necklace {
    font-size: 60px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #292929;
}

.shop-wrapper-right {
    max-width: 60vw;
    width: 100%;
}

.shop-input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #CECBC8;
    border-radius: 3px;
    margin: 0 15px;
    width: 60px;

    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #929292;
    }
}

.selected-size {
    background: #798F7D 0% 0% no-repeat padding-box;
    border: 1px solid #798F7D;
    color: white;
}

.shop-category {
    font: normal normal bold 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
}

.shop-cosmatics {
    font: normal normal normal 13px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    justify-content: space-between;
}

// .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
//     height: 15px;
//     min-height: auto;
// }
// .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {

//     height: 15px;
// }
.remove-border {

    .css-1086bdv-MuiPaper-root-MuiAccordion-root,
    .css-1aj41gs {
        box-shadow: none !important;
        border-bottom: none !important;
        margin-top: 0;
        padding-bottom: 0;
    }
}

.ex {
    .css-1086bdv-MuiPaper-root-MuiAccordion-root {
        box-shadow: none !important;
        // border-bottom: 1px solid #CECBC8;
        margin-top: 0;
        padding-bottom: 0;
    }

    .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
        min-height: auto;
    }
}

.shop-accordion-plus {
    width: 20px;
    height: 20px;
}

.shop-video {
    margin-top: 50px;
    // height: 300px;
    width: 100%;
    position: relative;
    // max-width: 60vw;
    // margin-left: auto;
    // margin-right: 50px;
    video {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}
.video-vis{
    display: none;
}
.shop-we {
    position: absolute;
    top: 10%;
    left: 10%;
    font-size: 55px;
    font-weight: 200;
    letter-spacing: 0px;
    color: #FFFFFF;
    z-index: 9;
    line-height: 1;
}

.shop-because {
    position: absolute;
    top: 50%;
    left: 14%;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #FFFFFF;
    z-index: 9;
    line-height: 1.1;
}

.shop-see-all {
    position: absolute;
    top: 70%;
    left: 14%;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #FFFFFF;
    z-index: 9;
    border: 1px solid #FFFFFF;
    padding: .75rem 4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.shop-arrow {
    width: 30px;
    height: 30px;
    object-fit: contain;
    // margin-left: 10px;
}

.shop-input2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #929292;
    width: 100%;
    padding: .4rem;
    border-radius: 3px;
    margin-bottom: 20px;

    &::placeholder {
        font: normal normal normal 11px Josefin Sans;
        letter-spacing: 0px;
        color: #CECBC8;
    }
}

.shop-jr {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

ol,
ul {
    padding-left: 0;
    margin-bottom: 0;
}

.fav-sort-default {
    .p-dropdown {
        border: 0;
    }
}


.shop {

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 30px;
    }

    .swiper-pagination-bullet-active {

        background: #292929 0% 0% no-repeat padding-box;
    }
}

.shop-filter-applied {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;

}

.shop-one-filter {
    display: flex;
    align-items: center;
    background: #F7F2EB 0% 0% no-repeat padding-box;
    border-radius: 3px;
    font: normal normal normal 13px Josefin Sans;
    width: fit-content;
    padding: .5rem;
    margin-right: 15px;
    margin-bottom: 15px;
}

.shop-close {
    fill: #B4804D;
    stroke: #B4804D;
    width: 20px;
    height: 20px;
    object-fit: contain;
    cursor: pointer;
}

.shop-close2 {

    width: 25px;
    height: 25px;
    object-fit: contain;
    cursor: pointer;
}

.shop-clear-filter {
    display: flex;
    align-items: center;
    font: normal normal normal 13px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    text-transform: uppercase;
    border-bottom: 1px solid #292929;
    margin-bottom: 30px;
    width: fit-content;
    cursor: pointer;
}

.shop-star {
    width: 25px;
    height: 25px;
    object-fit: contain;
}

.p-slider .p-slider-handle {
    border: 1px solid #798F7D;
    border-radius: 2px;
}

.p-slider .p-slider-range {
    background-color: #798F7D;
}

.p-inputswitch.p-highlight .p-inputswitch-slider {
    background: #798F7D;
}

.sort-select {
    border: none;
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #98A8B8;
    background-color: transparent;
    // border-right: 1px solid #B4804D;
    margin-right: 10px;
    padding-right: 5px;
    outline: none;
}

.accordion-cates {
    .css-1086bdv-MuiPaper-root-MuiAccordion-root {
        border: none !important;
        margin-top: 0 !important;
        padding-bottom: 0 !important;

    }

    .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
        min-height: 0 !important;
        width: 100% !important;
    }

    .css-eqpfi5-MuiAccordionSummary-content {
        margin: 0 !important;

    }

    .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
        min-height: 0 !important;
        width: 100% !important;
    }
}

.cates-icon {
    fill: #F0EEF3;
}

.sizes-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
}

//------------------------- check box -------------------
.custom-checkbox {
    display: flex;
    align-items: center;
}

.custom-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-checkbox label {
    position: relative;
    padding-left: 25px;
    margin-bottom: 7px;
    cursor: pointer;
    user-select: none;
}

.custom-checkbox label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 13px;
    height: 13px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 2px;
}

.custom-checkbox input[type="checkbox"]:checked+label:before {
    background-color: #798F7D;
}

.custom-checkbox input[type="checkbox"]:checked+label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.grey_nav-nav {
    cursor: pointer;
}

.selected_cate {
    border-bottom: 2px solid #F1C376;
}

.p-paginator {

    .p-paginator-first,
    .p-paginator-last {
        display: none;
    }

    .p-highlight {
        background-color: #798F7D;
        color: #FFFFFF;
    }
}


.silder-container {
    position: relative;
}

.filter-icon {
    display: none;
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: -110px;
    left: 20px;
    z-index: 9;
    cursor: pointer;
    
}
.filter-icon2 {
    display: none;
    // position: absolute;
    // width: 50px;
    // height: 50px;
    // bottom: -110px;
    // left: 20px;
    z-index: 9;
    margin-bottom: 20px;
    // cursor: pointer;
    
}
.filter-filter{
    color: #F1C376;
    margin-bottom: 0;
    text-align: center;
}
.filter-icon.no-images {
    bottom: -110px;
}
.filter-icon2.no-images {
    bottom: -110px;
}
.filter-icon2.images {
    bottom: -10px;
}
.img-close {
    display: none;
}

.grey_nav2 {
    justify-content: start;
}

.grey_nav3 {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.special-title {
    color: #292929;
}

.no-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

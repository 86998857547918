$width: 662px;


@media (max-width: #{$width}) {

    
  #login-login{
    text-transform: uppercase;
  }
  .login-please{
    font-size: 15px;
  }
  .login-pass-cont{
    width: 100%;
  }
 
}
.terms {
    width: 100vw;
    margin-top: 160px;
    margin-bottom: 200px;
    position: relative;
}

.terms-wrapper {
    position: relative;
    width: 100%;
    // height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}

.terms-privacy {
    font: normal normal 600 35px Josefin Sans;
    color: #292929;
    text-transform: uppercase;
    align-self: center;
}

.terms-contact {
    font: normal normal normal 22px Josefin Sans;
    color: #292929;
    margin-top: 30px;
}

.terms-the2 {
    font: normal normal normal 13px Josefin Sans;
    color: #929292;
    margin-top: 30px;
    line-height: 1.4;

    span {
        font: normal normal normal 13px Josefin Sans;
        letter-spacing: 0px;
        color: #292929;
    }

    p {
        margin: 0
    }

    ;
}
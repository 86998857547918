$width: 662px;


@media (max-width: #{$width}) {
    #privacy-faq{
        text-transform: uppercase;
    }
    .faq-contact{
        font: normal normal normal 16px Josefin Sans;
        margin-top: 50px;
    }
}
.orders {
    width: 100vw;
    margin-top: 140px;
    margin-bottom: 200px;
}

.orders-wrapper {
    position: relative;
    width: 100%;
    // height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
}

.orders-orders {
    font: normal normal 600 40px Josefin Sans;
    color: #292929;
    text-transform: uppercase;
    margin-top: 50px;
position: relative;
}

.orders-you {
    margin-top: 30px;
    font-size: 15px;
}

.orders-start {
    background-color: #798F7D;
    padding: .75rem 2rem;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 20px;

}

.orders-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 20px;
    /* Adjust the second value (vertical spacing) as needed */
}

.orders-table {}

.orders-th {
    font: normal normal normal 12px Josefin Sans;
    color: #929292;
    text-transform: uppercase;
    border-bottom: 1px solid #CECBC8;
}

.orders-tr {
    background: #FAF9F7 0% 0% no-repeat padding-box;
    margin-top: 20px;
}

.orders-img {
    width: 150px;
    height: 150px;
    object-fit: contain;
}
.orders-request-img {
    width: 150px;
    height: 150px;
    object-fit: contain;
   
}
.orders-td {
    display: flex;
    align-items: center;
}

.orders-golden-color {
    display: flex;
    flex-direction: column;
}

.orders-golden {
    text-decoration: underline;
    font: normal normal 600 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
}

.orders-color {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    margin-top: 10px;
}

.orders-flower-img {
    position: absolute;
    top: -100px;
    left: 0;
    width: 350px;
    height: 350px;
    transform: rotate(100deg);
    z-index: -1;
}

.orders-flower-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.orders-start-none{
    display: none;
}
.orders-table-mob{
    display: none;
}
.orders-table-res{
    display: none;
}

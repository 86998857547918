.footer {
    background: #798F7D 0% 0% no-repeat padding-box;
    position: relative;

    // margin-top: 400px;
}

.footer-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding: 0 2rem;
    padding-top: 200px;

}

.footer-flower-img4 {
    position: absolute;
    bottom: -20%;
    left: 0%;
    width: 150px;
    height: 200px;
}

.footer-flower-img4 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.footer-abs-imgs {
    position: absolute;
    top: -100px;
    left: 0%;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.footer-img1 {
    // position: absolute;
    // top: -100px;
    // left: 0%;
    width: 260px;
    height: 250px;
}

.footer-img1 img,
.footer-img2 img,
.footer-img3 img,
.footer-insta img {
    height: 100%;
    width: 100%;
    // object-fit: contain;
}

.footer-img2 {
    // position: absolute;
    // top: -100px;
    // left: 25%;
    width: 260px;
    height: 250px;
}

.footer-img3 {
    // position: absolute;
    // top: -100px;
    // left: 52%;
    width: 260px;
    height: 250px;
}

.footer-img4 {
    // position: absolute;
    // top: -100px;
    // right:  0%;
    width: 260px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #B4804D 0% 0% no-repeat padding-box;
    border-radius: 3px;
}

.footer-insta {
    width: 50px;
    height: 50px;
}

.footer-follow {
    text-align: center;
    font: normal normal normal 16px Josefin Sans;
    letter-spacing: 0px;
    color: #F7E6C4;
    width: 128px;
    margin-top: 30px;
    text-decoration: none;
}

.footer-nav {
    display: flex;
    flex-direction: column;
    position: relative;
}

.footer-short-listed {
    width: 80px;
    height: 70px;
    margin-bottom: 50px;
}

.footer-short-listed img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.footer-boston {
    text-align: left;
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #F7E6C4;
    margin-bottom: 15px;
    text-decoration: none;
    cursor: pointer;
}

.footer-support {
    text-align: left;
    font: normal normal 600 15px Josefin Sans;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.footer-input {
    width: 100%;
    padding: 1rem;
    margin-bottom: 15px;

    &::placeholder {
        font: normal normal normal 15px Josefin Sans;
        letter-spacing: 0px;

        color: #ECE0D4;
    }
}

.footer-social {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.footer-social img {
    width: 25px;
    height: 25px;
    object-fit: contain;
    margin-right: 15px;
}

.footer-boston2 {
    text-align: left;
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #F7E6C4;

}

.footer-boston3 {
    text-align: left;
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #F7E6C4;
    margin-bottom: 15px;
}

.footer-cash-methods {
    display: flex;
    justify-content: center;
}

.footer-cash-methods img {
    width: 40px;
    height: 30px;
    object-fit: contain;
    margin-right: 5px;
}

.footer-brown-line {
    width: 100%;
    height: 1px;
    background-color: #F7E6C4;
    margin-top: 20px;
}

.footer-copy {
    margin-top: 20px;
    color: #F7E6C4;
    font-size: 15px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    padding: 0 2rem;
    padding-bottom: 20px;
   
}

.footer-input-cont {
    position: relative;
}

.footer-input-img {
    position: absolute;
    right: 5%;
    top: 8%;
    width: 40px;
    height: 50px;
    object-fit: contain;
    cursor: pointer;
}

.footer-rel {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-social-flex {
    display: flex;
    margin-bottom: 20px;
}

.footer-accordion,
.footer-details {
    display: none;

}
$width: 662px;


@media (max-width: #{$width}) {

    
   #recover-recover{
    text-transform: capitalize;
    font-size: 20px;
   }
   .login-input{
    width: 100%;
   }
   .login-send{
    width: 100%;
   }
   .privacy{
    padding-bottom: 150px;
    margin-top: 80px;
   }
   .recover-flower-img {
     
      width: 150px;
      height: 250px;
  }
  #recover-rem{
   font-size: 12px;
  }
}
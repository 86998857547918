$width: 662px;


@media (max-width: #{$width}) {
    .paymentModal-input {
        padding: .75rem;
        padding-left: 4rem;
    }

    .paymentModal-master {
        top: 30%;
        height: 30px;
    }
    .paymentModal-cards{
        flex-direction: column;
    }
    .paymentModal-input2{
        padding: .75rem;
    }
    .paymentModal-cancel-add {
       
        justify-content: space-between;
        width: 100%;
    }
    .paymentModal-add{
        font-size: 20px;
    }
    .paymentModal-cash-methods{
        margin-top: 50px;
    }
}
@font-face {
    font-family: 'RollgatesLuxury';
    src: url('./Rollgates\ Luxury.otf') format('woff2'),
         url('./Rollgates\ Luxury.ttf') format('woff');
  }
  
  @font-face {
    font-family: 'RollgatesLuxuryItalic';
    src: url('./Rollgates\ Luxury\ Italic.ttf') format('woff2'),
         url('./Rollgates\ Luxury\ Italic.otf') format('woff');
  }
.fav-details-images {
    display: flex;
    // gap: 25px;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    // padding: 0 1rem;
}


.fav-details-col {
    flex-direction: column;
}

.details-link {
    position: absolute;
    top: 0%;
    // right: 50%;
    left: 0;
    right: 0;
    width: fit-content;
    padding: 0.25rem .5rem;
    border-radius: 5px;
    background-color: #292929;
    color: white;
    margin-left: auto;
    margin-right: auto;
    z-index: 99;
}
.details-link2 {
    position: absolute;
    top: 100%;
    // right: 50%;
    left: 0;
    right: 0;
    width: fit-content;
    padding: 0.25rem .5rem;
    border-radius: 5px;
    background-color: #292929;
    color: white;
    margin-left: auto;
    margin-right: auto;
    z-index: 99;
}
.details-link-window{
    position: absolute;
    top: 0%;
    // right: 50%;
    left: 0;
    right: 0;
    width: fit-content;
    padding: 0.25rem .5rem;
    border-radius: 5px;
    background-color: #292929;
    color: white;
    margin-left: auto;
    margin-right: auto;
}


.fav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px solid #FAF9F7;
    padding-left: 0;
    padding-right: 0;
    margin-left: 3vw;
    margin-bottom: 20px;

    &:hover {
        border: 2px solid #B4804D;
        border-radius: 3px;

        .fav-love,
        .fav-love2,
        .fav-love-end,
        .fav-love2-end,
        .fav-add-col {
            display: block;
        }

        .home-golden,
        .home-price {
            display: none;
        }

    }
}

.fav-menu-col {
    flex-direction: row;
    align-items: flex-start;
    gap: 50px;
    width: auto;
    padding: 1rem;
}

.fav-menu-cont1-col {
    width: 200px !important;
    height: 220px !important;
}

.fav-menu-cont1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 300px;
    background: #FAF9F7 0% 0% no-repeat padding-box;
    position: relative;
    // border: 2px solid #B4804D;
    border-radius: 3px;
    cursor: pointer;


}

#home-menu-cont2,
#home-menu-cont1 {
    height: 250px;
}

.fav-items-sort {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ECE0D4;
    padding-bottom: 5px;
}

.fav-sort {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #98A8B8;
    border-right: 1px solid #B4804D;
    margin-right: 10px;
    padding-right: 5px;
}

.fav-sort-default {
    display: flex;
    align-items: center;
}

.fav-default {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    border-right: 1px solid #CECBC8;
    margin-right: 10px;
    padding-right: 5px;
}

.fav-4-items {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
}

.fav-flower-img {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0%;
    left: 0%;
}

.fav-window {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    cursor: pointer;
}

.fav-golden-col {
    font: normal normal 600 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 30px;
    width: 80%;
}

.fav-65-col {
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    opacity: 1;
    margin-top: 15px;
}

.fav-lorem-col {
    display: none;
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    max-width: 75%;

    margin-top: 20px;
}

fav-lorem-col-static {
    width: 75%;
}



.love-share {
    position: absolute;
    top: 3%;
    right: 5%;
    display: flex;
    flex-direction: column;
}

.fav-text-col {
    display: flex;
    flex-direction: column;
    width: 75%;
    position: relative;
}

.end-cart {
    right: -400%;
}

.home-golden2 {
    display: none;
}

.fav-add-col {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: center;
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    text-transform: uppercase;
    padding: 1.25rem 0;
    width: 95%;
    cursor: pointer;
    display: none;
    margin-bottom: 20px;
}

.fav-love {
    display: none;
    position: absolute;
    top: 3%;
    right: 5%;
    width: 40px !important;
    height: 40px !important;
    object-fit: contain;
    cursor: pointer;
    z-index: 9;
}

.fav-love-end {
    display: none;
    // position: absolute;
    // top: 3%;
    // right: 5%;
    width: 40px !important;
    height: 40px !important;
    object-fit: contain;
    cursor: pointer;
}

.fav-love2 {
    display: none;
    position: absolute;
    top: 25%;
    right: 5%;
    width: 40px !important;
    height: 40px !important;
    object-fit: contain;
    cursor: pointer;
    z-index: 9;
}
#shop-love2 {
   
    top: 20%;
   
}

.fav-love2-end {
    display: none;
    // position: absolute;
    // top: 25%;
    // right: 5%;
    width: 40px !important;
    height: 40px !important;
    object-fit: contain;
    cursor: pointer;
}

.fav-abs-no {
    position: absolute;
    top: -8px;
    right: -12px;
    border-radius: 50%;
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.25rem;
    background-color: #7F9C89;
    font: normal normal normal 12px Josefin Sans;
    color: white;
}

.fav-abs-no2 {
    width: 5px;
    height: 5px;
    top: 0;
    right: 0;
    padding: 0;
}
$width: 662px;
$width2: 1000px;

 @media (min-width: #{$width}) and (max-width: #{$width2}) {
    .footer-wrapper{
        padding: 0 2rem;
        padding-top: 150px;
        flex-wrap: wrap;
        gap: 20px;
    }
    .footer-img4,.footer-img1,.footer-img2,.footer-img3{
        width: 160px;
        height: 180px;
    }
    .footer-img4{
        right: 2.5%;
    }
    .footer-img1{
        left: 2.5%;
    }
    .footer-img2{
        left: 27.5%;
    }
 }
@media (max-width: #{$width}) {

    .footer-img1,
    .footer-img2,
    .footer-img3,
    .footer-img4 {
        width: 100px;
        height: 100px;
        top: -50px;
    }

    .footer-abs-imgs{
        top: -50px;
    }
    .footer-img1 {
        left: 37%;
    }

    .footer-img2 {
        left: 6%;
    }

    .footer-img3 {
        left: 51%;
        display: none;
    }

    .footer-img4 {
        left: 68%;
    }

    .modal-lizzard {
        position: absolute;
        top: -40px;
        right: -10px;
        width: 70px;
        height: 100px;
    }

    .footer-follow,
    .footer-flower-img4 {
        display: none;
    }

    .footer-insta {
        width: 40px;
        height: 40px;
    }

    .footer-wrapper {
        flex-direction: column;
        align-items: center;
        padding-top: 100px;
    }

    // .footer-nav {
    //     align-items: center;
    // }

    .footer-input-cont {
        width: 100%;
    }

    .footer-social {
        margin-bottom: 0;
    }

    .footer-social-flex {
        margin-bottom: 0;
    }


    .footer-short-listed {
        width: 100px;
        height: 90px;
    }

    #footer-new {
        text-align: center;
    }

    .footer-boston {
        margin-bottom: 20px;
        font: normal normal normal 15px Josefin Sans;
       
    }

    .footer-details-orange-loc {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .footer-boston:last-child {
            margin-bottom: 0;
        }
    }

    #footer-nav-support,
    #footer-nav-products,
    #footer-nav-907,
    #footer-nav-boston,
    #footer-nav-just {
        display: none;
    }

    .footer-accordion {
        display: block;
        width: 100%;

        .css-1086bdv-MuiPaper-root-MuiAccordion-root ,.css-1aj41gs{
            background-color: #798F7D !important;
            padding-bottom: 0;
            border-bottom: 0;
            margin-top: 0;
        }

    }

    #footer-acc {
        .css-1086bdv-MuiPaper-root-MuiAccordion-root ,.css-1aj41gs {
            border-top: 1px solid #F7E6C4;
            border-bottom: 1px solid #F7E6C4;
            margin-top: 30px;
        }

        .css-1086bdv-MuiPaper-root-MuiAccordion-root:last-of-type ,.css-1aj41gs:last-of-type{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .css-1086bdv-MuiPaper-root-MuiAccordion-root:first-of-type ,.css-1aj41gs:first-of-type{
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .footer-acc-nav {
        display: flex;
        flex-direction: column;
    }

    .footer-support {
        text-align: center;
    }

    #footer-support {
        color: white;
        font-size: 20px;
    }

    #footer-down,
    #footer-up {
        fill: white;
        width: 15px;
        height: 15px;
    }

    .footer-boston3 {
        margin-top: 20px;
        text-align: center;
    }

    .footer-details {
        display: block;
    }
}
.collections-flower-img{
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 200px;
    transform: rotate(90deg);
}
.collections-flower-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.collections-discover{
text-align: center;
font: normal normal normal 13px Josefin Sans;
letter-spacing: 0px;
color: #292929;
margin-top: 30px;
}
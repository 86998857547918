$width: 662px;


@media (max-width: #{$width}) {
    .privacy-contact3{
        font: normal normal normal 16px Josefin Sans;
    }
    // .privacy{
    //     margin-top: 80px;
    // }
}
$width: 662px;
$width2: 1050px;

@media (min-width: #{$width}) and (max-width: #{$width2}) {
    .modal-kite-img {
        width: 150px;
        height: 150px;

    }

    .detials-ring-img {
        width: 300px;
    }

    .details-add {
        padding: .5rem;
    }

    .details-grey-line {
        padding-left: 30px;
    }

    .details-left-right {
        gap: 50px;
    }

    .details-ring-imgs {
        max-width: 400px;
    }
}

@media (max-width: #{$width}) {
    .details {
        margin-top: 80px;
    }

    .details-left-swiper {
        display: block;
        width: 100%;
    }

    #details-res {
        padding: 0;
    }

    .details-swiper-img-2-swiper {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .details-swiper-img-2-swiper img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .details-menu2-swiper {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 450px;
    }

    .details-products-swiper {
        display: flex;
        gap: 30px;
        margin-bottom: 15px;
        justify-content: center;
        width: 100%;
        height: 250px;
    }

    .details-left {
        display: none;
    }

    .details-grey-line {
        padding-left: 20px;

    }

    .details-link {
        font-size: 12px;
    }

    .details-left-right {
        flex-direction: column;
        gap: 0;
        padding: 1rem;
    }

    .detials-ring-img {
        margin-top: 0;
        width: 100%;
        height: 270px;
    }

    .details-love2 {
        top: 23%;
    }

    .details-ring-imgs {
        display: none;
    }

    .details-right {
        max-width: 100%;
    }

    .details-golden {
        font-size: 25px;
    }

    .detailsModal-plus-minus {
        padding: 0.25rem 0.75rem;
    }

    .details-add {
        padding: 1rem 0.2rem;
        width: auto;
    }

    .details-number {
        margin: 0;
        width: 20px;
    }

    .details-wrapper-1 {
        height: auto;
    }

    .details-wrapper-2 {
        padding: 1rem;
    }

    .details-ul {
        padding: 0 1rem;
    }

    #details-back-front-imgs {
        display: none;
    }

    #details-new-arrival {
        font-size: 30px;
        text-align: center;
    }

    .details-new-arrival-back-front-imgs {
        justify-content: center;
    }

    .details-menu {
        width: 180px;
    }

    .details-plus,
    .details-minus {
        margin: 0 15px;
    }

    .details-swiper-img-1 {
        width: 100%;

        // img {
        //     object-fit: fill;
        // }
    }

    #details-swiper-img-1 img {
        object-fit: cover;
    }

    .home-golden {
        font-weight: 400 !important;
        font-size: 15px !important;
    }

    .details {
        // margin-top: 80px;
    }

    .details-grey-line {
        height: 40px;
    }

    .modal-body-cont {
        flex-direction: column-reverse;
        padding: 0 1rem;
    }

    .modal-swiper {
        width: 100%;
    }

    .modal-kite-img {
        width: auto;
        height: auto;
    }

    .modal-golden {
        font-size: 15px;
        margin: 20px;
    }

    .modal-rebel-img {

        margin-left: auto;
        margin-right: auto;
    }

    .modal-brought {
        font-size: 13px;
    }

    .modal-explore {
        width: 100%;
        justify-content: space-between;
        margin-top: 100px;
    }
}
$width: 662px;
$width2: 1000px;

@media (min-width: #{$width}) and (max-width: #{$width2}) {

    // .home-first-discover{
    //     left: 5%;
    // }
    // .home-first-img{
    //     width: 180px;
    //     height: 150px;
    // }
    // .home-discover{
    //     font-size: 40px;
    // }
    // .home-middle-img{
    //     width: 200px;
    //     height: 400px;
    //     img{
    //         object-fit: cover;
    //     }
    // }
    // .home-pink-background{}
    // }
    // .home-pink-img{
    //     width: 120px;
    //     height: 250px;
    // }
    // .home-shop-now{
    //     padding: .5rem 1rem;
    // }
    // .home-wrapper-1{
    //     height: 80vh;
    // }
    .header-added-img {
        height: 150px;
        width: 150px;
    }
}

@media (max-width: #{$width}) {
    .home {
        margin-top: 80px;
    }
    #brand-see-all{
        margin-right: auto;
        padding: .25rem;
        width: 100px;
        margin-top: 0;
        font: normal normal 300 15px Josefin Sans;
        border: .5px solid #292929;
        margin-bottom: 20px;
    }
    // .home-pink-background,.home-first-img{
    //     display: none;
    // }
    .home-products-swiper2 {
        justify-content: center;
    }

    .home-pink-background {
        height: 30vh;
    }

    .home-first-discover {
        margin-top: 0;
        left: 5vw;
    }

    .home-shop-now {
        display: none;
    }

    .home-pink-img {
        height: 17vh;
        width: 17vw;
    }

    .home-first-img {
        margin-top: 25px;
        height: 11vh;
        width: 25vw;
        margin-left: 0;

        img {
            border-radius: 5px;
        }
    }

    .home-wrapper-1 {
        height: 60vh;
    }

    .home-middle-img {
        height: 25vh;
        margin-top: 15px;

        width: 35vw;
        left: 35%;

        img {
            border-radius: 5px;
        }
    }

    // .home-middle-img{
    //     height: 90%;
    //     width: 100%;
    //     left: 0;
    //     margin-top: 0;
    //     img{
    //         object-fit: cover;
    //     }
    // }
    // .home-first-discover{
    //     margin-left: 20px;
    //     margin-top: 220px;
    // }
    .home-discover {
        font-size: 40px;
        margin-top: 120px;
        margin-left: 6vw;
        line-height: 1.2;
    }

    .home-discover p {
        margin-left: 40px;
    }

    .swiper-pagination {
        display: block;
    }

    .home-modern {
        z-index: 6;
        margin-top: 0;
        font-size: 15px;
        margin-left: 18vw;
        width: auto;
    }

    .home-wrapper-2 {
        height: auto;
    }

    .home-featured,
    .home-new-arrival {
        font-size: 30px;
    }

    .home-back-front-imgs {
        display: none;
    }

    .home-new-arrival-back-front-imgs {
        justify-content: center;

    }

    .home-swipers {
        padding: 1rem;
    }

    .fav-menu-cont1 {
        width: 200px;
        height: auto;

    }

    .home-social {
        text-align: center;
        font-size: 30px;
    }

    .home-insta {
        font-size: 22px;
    }

    .home-post-img {
        width: auto;
    }

    .home-pag .swiper-pagination-bullet-active {
        background: #292929;
    }

    .home-product-img-1,
    .home-rings,
    .home-see-all {
        margin-left: auto;
        margin-right: auto;
    }

    .home-rings {
        font-size: 13px;
    }

    .home-see-all {
        padding: .5rem 1rem;
    }

    .home-product-1:nth-of-type(2) {
        margin-top: 0;
    }

    .home-product-1:nth-of-type(3) {
        margin-top: 0;
    }

    .test-insta {
        position: relative;
        // height: 300px !important;
    }

    // .overlay {
    //     position: absolute;
    //     top: 1px;
    //     right: 0px;
    //     width: 27%;
    //     height: 54px;
    //     background-color: white;
    //     z-index: 10;
    // }
}
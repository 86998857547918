.header {
    // height: 160px;
    background: #FFFFFFF5 0% 0% no-repeat padding-box;
    position: fixed;
    top: 0;
    width: 100vw;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 10;
    flex-direction: column;
    overflow: visible;
    // position: relative;
}

.header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    padding: 1rem;
    padding-bottom: 0;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
    
}
.header-padd{
    padding: 3rem 1rem;
    // padding-top: 1rem ;
    // position: relative;
}
.header-logo {
    width: 70px;
    height: 60px;
    text-decoration: none;
}

.header-logo img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.header-shop {
    cursor: pointer;
    text-decoration: none;
    color: #292929;
}

.header-underline {
    border-bottom: 2px solid #F1C376;
}

.header-arrow-down {
    width: 10px;
    height: 10px;
    object-fit: contain;
    margin-left: 5px;
}

.header-nav {
    display: flex;
    align-items: center;
    gap: 30px;
}

.header-nav2 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-nav2-image {
    width: 20px;
    cursor: pointer;
    height: 20px;
    position: relative;
}

.header-nav2-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.header-wrapper2 {
    background-color: #F5F5F5;
    height: 350px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: absolute;
    bottom: -350px;
    left: 0;
}

.header-wrapper4 {
    background-color: #F5F5F5;
    // height: 350px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    padding: 2rem 0;



    
    // height: 350px;
    position: absolute;
    bottom: -425px;
    left: 0;
}

.header-wrapper3 {
    background-color: #F5F5F5;
    height: 350px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: absolute;
    bottom: -350px;
    left: 0;
}

.header-added-section {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    // align-items: center;
}
.header-added-img {
    height: 220px;
    width: 220px;

}

.header-added-img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.header-added-img2 {
    height: 160px;
    bottom: 7.5%;
    width: 90%;
    right: 0;
    position: absolute;
    
}

.header-added-img2 img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.header-cosmatics {
    text-align: center;
    font-size: 30px;
    letter-spacing: 0px;
    color: #292929;
    font-weight: 300;
    margin-top: 15px;
    text-decoration: none;
    font-family: 'RollgatesLuxury';
}

.header-wrapper3-cont {
    max-width: 1100px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
}

.header-brand-section {
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 140px;
}

.header-jr {
    font-size: 15px;
    color: #929292;
    margin-bottom: 10px;
    text-decoration: none;
    // margin-right: 100px;
    width: 150px;
    cursor: pointer;
}

.header-brands {
    margin-bottom: 30px;
    font-weight: 700;
}

.header-directions-swiper {
    display: flex;
    align-items: center;
    max-width: 1200px;
    width: 100%;
}

.header-left-img {
    width: 80px;
    height: 80px;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.header-right-img {
    width: 80px;
    height: 80px;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.header-post-img {
    width: 230px;
    height: 250px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.header-abs-search{
    display: none;
}
.header-see-all {
    border: 1px solid #292929;
    border-radius: 40px;
    padding: .5rem 1rem;
    cursor: pointer;
    width: fit-content;
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    text-decoration: none;
    color: #292929;

}
.home-see-all-link{
    color: black;
    text-decoration: none;
}
.header-search {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 500px;
    display: flex;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
}

.header-search-logo {
    width: 150px;
    height: 150px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.header-search-bird {
    width: 50px;
    height: 105px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.header-logos {
    display: flex;
    margin-top: 50px;
}

.header-search-input {
    border: 0;
    border-bottom: 1px solid #ECE0D4;
    width: 100%;
    margin-top: 50px;
    outline: none;
    font: normal normal 300 15px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    padding-bottom: 20px;

    &::placeholder {
        font: normal normal 300 15px Josefin Sans;
        letter-spacing: 0px;
        color: #929292;
    }
}

.header-search-recent {
    font: normal normal 300 15px Josefin Sans;
    letter-spacing: 0px;
    display: flex;
    margin-top: 50px;
    margin-right: 100px;
    color: #B4804D;
    align-self: flex-start;
}

.header-search-cont {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-search-recents {
    display: flex;
    align-self: flex-start;
    margin-top: 30px;
}

.header-search-golden {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    background: #FAF9F7 0% 0% no-repeat padding-box;
    border-radius: 40px;
    padding: .5rem;
    margin-right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
}

.header-search-close-img {
    width: 10px;
    height: 10px;
    margin-left: 10px;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.header-search-close-img2 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 15%;
    right: 10%;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.header-search-flower{
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 100px;
    height: 100px;
}
.header .swiper-pagination-bullet-active {
    background: #292929 0% 0% no-repeat padding-box;
}
.header-abs-no {
    position: absolute;
    top: 0;
    right: -2px;
    border-radius: 50%;
    padding: .15rem .15rem;
    background-color: #7F9C89;
    font: normal normal normal 12px Josefin Sans;
    color: white;
}
.header-search-cont-img{
    position: relative;  width: 100%;
}
.header-search-img{
    position: absolute;
    right: 0;
    bottom:  20px;
    width: 20px;
    height: 20px;
    object-fit: contain;
    cursor: pointer;
}
.header-wrapper-resp{
    display: none;
    width: 100%;
    padding: 1rem;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
}
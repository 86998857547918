
.designers-flower-img{
    position: absolute;
    top: -10%;
    left: 0;
    width: 550px;
    height: 500px;
}
.designers-flower-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.designers-discover{
    text-align: center;
font: normal normal normal 13px Josefin Sans;
letter-spacing: 0px;
color: #292929;
margin-top: 30px;
}
.designers-imgs{
    display: flex;
    margin-top: 50px;
    justify-content: flex-start;
    padding: 0 15px;
    gap: 50px;
    flex-wrap: wrap;
}
.designers-wrapper {
    position: relative;
    width: 100%;
    // height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;

    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
}
.designers-img{
    width: 220px;
    height: 220px;
    img{
        width: 100%;
        height: 100%;
        // object-fit: contain;
    }
}
.designer-img-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}
.designers-rebel{
    font: normal normal 600 15px Josefin Sans;
letter-spacing: 0px;
color: #292929;margin-top: 15px;
}
.designers-123{
    font: normal normal normal 15px Josefin Sans;
letter-spacing: 0px;
color: #929292;
text-transform: capitalize;margin-top: 15px;
}
.address {
    width: 100vw;
    margin-top: 140px;
    margin-bottom: 250px;
}

.address-wrapper {
    position: relative;
    width: 100%;
    // height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 0 2rem;
}

.address-orders {
    font: normal normal 600 40px Josefin Sans;
    color: #292929;
    text-transform: uppercase;
    margin-top: 50px;
    position: relative;
    text-align: center;
}
.addressModal-fst-lst{
    display: flex;
}
.address-you {
    margin-top: 30px;
    font-size: 15px;
    text-align: center;
}

.address-location {
    background: #FAF9F7 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 4rem;
     margin-top: 50px;
}

.address-img {
    width: 20px;
    height: 20px;
    margin-bottom: 20px;
}

.address-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.address-add {
    font-size: 15px;
    text-decoration: underline;
}





.custom-modal {
    padding-right: 0 !important;
    .modal-content {
        height: 100%;
    }
    .modal-dialog {
        margin: 0 !important;
        margin-left: auto !important;
        // height: 100%;
        max-width: 600px !important;
    }
}
.detailsModal-add-shipping{
width: 100%;
display: flex;
justify-content: space-between;
margin-top: 30px;
}
.addressModal-add {
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
}
.addressModal-add-mobile{
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    display: none;
   
}
.addressModal-add-close {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 1rem;
    margin-bottom: 30px;
}

.addressModal-body-cont {
    display: flex;

    width: 100%;
    padding: 3rem 1rem;
    margin-left: auto;
    margin-right: auto;

    flex-direction: column;
    height: 90%;
    border-top: 1px solid #F5F5F5;
}

.addressModal-please {
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
}

.addressModal-input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    padding: .5rem 1rem;
    margin-top: 15px;

    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #CECBC8;
    }
}

.addressModal-input2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    padding: .5rem 1rem;
    margin-top: 15px;
    width: 50%;
    margin-right: 10px;

    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #CECBC8;
    }
}

.addressModal-input3 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    padding: .5rem 1rem;
    margin-top: 15px;
    width: 50%;


    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #CECBC8;
    }
}
.addressModal-input5 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    padding: .5rem 1rem;
    // margin-top: 15px;
    // width: 50%;


    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #CECBC8;
    }
}


.addressModal-save {

    background: #798F7D 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #fff;
    padding: .75rem;
    text-align: center;
cursor: pointer;
}
.addressModal-add-new-mobile{
    display: none;
}
.addressModal-flower{
    display: none;
}
.addressModal-check-save {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 15px;

    input {
        margin-right: 10px;
    }
}

.addressModal-set {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
}

.p-dropdown-panel {
    z-index: 9999999999999 !important;
}

.address-boston {
    font: normal normal  12px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    text-transform: uppercase;
}
.address-location2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    width: 270px;
    height: 150px;
    padding: 1rem 2rem;
    // margin-top: 50px;
    padding-right: 4rem;
    // z-index: 98;
    overflow: auto;
}
#address-location2{
    height: 200px;
}
.address-07 {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    margin-top: 30px;
}

.address-flower{
    position: absolute;
    right: 0%;
    bottom: 0;
    width: 400px;
    height: 300px;
}

.address-container{
    display: flex;
    max-width: 810px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    margin-top: 30px;
}

.error{
    margin-top: 5px;
    color: red;
    width: 270px;
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
}






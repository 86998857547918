.cart-black-line {
    border: 3px solid #292929;
    opacity: 1;
    width: 450px;
    border-radius: 10px;
    display: flex;
    align-self: center;
    margin-top: 20px;
}

.cart-right-left {
    display: flex;
    margin-top: 50px;
    justify-content: space-around;
    width: 100%;
}

.cart-left {
    display: flex;
    flex-direction: column;
    width: 500px;
}

.cart-product-subtotal {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #CECBC8;
    padding-bottom: 10px;
}

.cart-product {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    text-transform: uppercase;
}

.cart-right {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.cart-right-rect {
    width: 100%;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    padding: 2rem 1.5rem;
    position: relative;
}

.cart-total-130 {
    display: flex;
    justify-content: space-between;
}

.cart-shipping {
    margin-top: 10px;
    font: normal normal normal 13px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
}

.cart-add {
    font: normal normal normal 13px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 20px;
}

.cart-textarea {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    width: 100%;
    margin-top: 10px;
    padding: .5rem;
    height: 100px; font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
   

    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #CECBC8;
    }
}

.cart-checkout {
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    background: #798F7D 0% 0% no-repeat padding-box;
    border-radius: 3px;
    padding: 1rem;
    text-align: center;
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.cart-we{
    font: normal normal normal 12px Josefin Sans;
letter-spacing: 0px;
color: #929292;
display: flex;
align-self: center;
margin-top: 10px;
}
.cart-total{
    font: normal normal normal 15px Josefin Sans;
letter-spacing: 0px;
color: #292929;
}
.cart-bird{
    position: absolute;
    top: -70px;
    right: -40px;
    width: 120px;
    height: 120px;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
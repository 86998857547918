$width: 662px;


@media (max-width: #{$width}) {

    .cart-right-left{
        flex-direction: column;
        gap: 100px;
        
        align-items: center;
    }
    .cart-left{
        width: 100%;
    }
    .privacy-wrapper{
        padding: 0 2rem;
    }
    .cart-black-line{
        width: 100%;
        border-width: 4px;
    }
    .cart-product-subtotal,#cart-home-back-front-imgs{
        display: none;
    }
    #cart-new-arrival{
        font-size: 30px;
        text-align: center;
    }
    
}
.login-please {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 30px;
    text-align: center;

    .login-span {
        text-decoration: underline;
        color: #292929 !important;
    }
}

.error-msg {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: red;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;

    // margin-top: 30px;
    // text-align: center;
}

.login-input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    margin-top: 30px;
    width: 65%;
    padding: .75rem;
    margin-left: auto;
    margin-right: auto;

    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #929292;
    }
}

.login-input2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    margin-top: 10px;
    width: 65%;
    padding: .75rem;
    margin-left: auto;
    margin-right: auto;

    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #929292;
    }
}
.login-input3 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    margin-top: 10px;
    width: 100%;
    padding: .75rem;
    margin-left: auto;
    margin-right: auto;

    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #929292;
    }
}

.login-send {
    background: #798F7D 0% 0% no-repeat padding-box;
    border-radius: 3px;
    padding: 1rem;
    color: #fff;
    text-align: center;
    margin-top: 20px;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}


.login-flower-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 450px;
    height: 400px;
}

.login-flower-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.login-pass-cont {
    position: relative;  width: 65%; margin-left: auto;
    margin-right: auto;
}

.login-forget {
    text-decoration: underline;
    font: normal normal normal 13px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    position: absolute;
    top: 50%;
    right: 20px;
  
}
$width: 662px;


@media (max-width: #{$width}) {
    .orders{
        margin-top: 80px;
    }
    .orders-flower-img {

        width: 100px;
        height: 250px;
    }

    .orders-table {
        display: none;
    }

    .orders-table-mob {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        width: 100%;
        gap: 20px;

    }

    .orders-table-cont {
        border: 1px solid #FCE6CF;
        border-radius: 3px;
        display: flex;
        gap: 20px;
        padding: .5rem;
        flex-direction: column;
    }

    .orders-table-cont-black {
        display: flex;
        gap: 20px;
    }

    .order-table-content {
        display: flex;
        flex-direction: column;
    }

    .order-table-return {
        width: 100%;
        padding: 1rem;
        background-color: #292929;
        text-align: center;
        color: white;
    }

    .order-table-rebel {
        font: normal normal normal 10px/13px Josefin Sans;
        letter-spacing: 3px;
        color: #B4804D;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .order-table-golden {
        margin-bottom: 10px;
        font: normal normal 600 12px/14px Josefin Sans;
        letter-spacing: 0px;
        color: #292929;

    }

    .order-table-size {
        margin-bottom: 10px;
        font: normal normal normal 12px/14px Josefin Sans;
        letter-spacing: 0px;
        color: #929292;
    }

    .orders-table-res {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 2rem;
    }

    .orders-order-res {
        border: 1px solid #FCE6CF;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        padding: .5rem;
        gap: 20px;
       
    }
    .orders-line2 {
        border: 1px solid #F2EDE6;
        border-radius: 10px;
        width: 100%;
        margin:20px 0;
        position: relative;
    }
    .orders-img-details{
        display: flex;
        gap: 20px;
    }
    .orders-details{
        font-size: 12px;
        font-weight: bold;
    }
    .orders-details2{
        color: #929292;
        font-size: 12px;
    }
    .orders-img-product{
        background-color: #FAF9F7;
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
    .orders-order-return-res{
        background: #292929 0% 0% no-repeat padding-box;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        text-decoration: none;
        gap: 10px;
    }
    .orders-request-res{
        text-decoration: none;
        color: white;
    }
}
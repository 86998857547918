.grey_nav{
    width: 100vw;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FAF9F7;
}
.grey_nav-wrapper{
    display: flex;
    align-items: center;
}
.grey_nav-break{
    background-color: #929292;
    width: 2px;
    height: 20px;
    margin: 0 15px;
}
.grey_nav-nav{
    text-align: center;
font: normal normal  15px Josefin Sans;
letter-spacing: 0px;
color: #929292;
text-decoration: none;
}
.grey_nav-active{
    text-align: center;
    font: normal normal medium 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    text-decoration: none;
    border-bottom: 2px solid #F1C376;

}
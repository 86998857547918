$width: 662px;



@media (max-width: #{$width}) {

    .recover-wrapper {
        padding: 2rem;
    }

    .privacy-privacy {
        font: normal normal 600 30px Josefin Sans;
        text-transform: lowercase;
        margin-top: 20px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .privacy-the {

        font: normal normal normal 15px Josefin Sans;
        line-height: 1.4;
    }

    .privacy-contact {
        font: normal normal normal 17px Josefin Sans;
        margin-top: 60px;
    }

    .return-contact-us {
        padding: 1rem 4rem;
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }
    .return-bird-img{
       
        height: 150px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
$width: 662px;


@media (max-width: #{$width}) {
    .grey_nav-break{
        margin: 0 8px !important;
    }
    .custom-mod{
        padding: 3rem 1rem !important;
    }
    .custom-modal .modal-dialog {
        height: 100vh;
    }
    .detailsModal-body-cont{
        padding: 0  !important;
        border-top: 0;
    }
    .detailsModal-add{
        display: none;
    }
    .detailsModal-line{
        border: 4px solid #292929;
    }
    .detailsModal-bird{
        left: -30px;
    }
    .detailsModal-you{
        font-size: 16px;
    }
    .detailsModal-add-shipping{
        justify-content: center;
    }
    .modal-close{
        width: 30px
    }
    .addressModal-add{
        visibility: hidden;
    }
    .addressModal-add-mobile{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .addressModal-add-new-mobile{
        text-align: center;
        display: block;
        font: normal normal normal 20px Josefin Sans;
    }
    .addressModal-body-cont{
        border: 0;
        padding: 0;
        height: auto;
    }
    .addressModal-please{
        text-align: center;
        margin-top: 15px;
        margin-bottom: 50px;
        font-size: 13px;
    }
    .addressModal-fst-lst{
        flex-direction: column;
    }
    .addressModal-input2,.addressModal-input3{
        width: 100%;
    }
    .custom-modal .modal-content{
        height: auto;
    }
    .addressModal-flower{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    .address-container{
        justify-content: center;
    }
    .address-07{
        margin-top: 10px;
    }
}
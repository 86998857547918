.paymentModal-body-cont {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.paymentModal-add {
    text-align: center;
    font: normal normal 600 40px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
}

.paymentModal-please {
    text-align: center;
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 30px;
}

.paymentModal-cash-methods {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.paymentModal-cash-methods img {
    width: 40px;
    height: 30px;
    object-fit: contain;
    margin-right: 5px;
}

.paymentModal-card {
    text-align: left;
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    margin-top: 20px;
    color: #292929;
    align-self: flex-start;
}
#address-location{
    height: 200px;
}
.payment-location {
    background: #FAF9F7 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 4rem;
    // margin-top: 50px;
    height: 150px;
    width: 270px;

    &:hover {
        background: #798F7D 0% 0% no-repeat padding-box;

        .address-add {
            color: #fff;
            cursor: pointer;
        }
    }
}
.payment-location3 {
    background: #FAF9F7 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem ;
    margin-top: 50px;
    height: 150px;
    width: 270px;

    &:hover {
        background: #798F7D 0% 0% no-repeat padding-box;

        .address-add {
            color: #fff;
            cursor: pointer;
        }
    }
}

.payment-location2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    width: 270px;
    height: 150px;
    padding: 1rem 2rem;
    margin-top: 50px;
    padding-right: 4rem;

    
}

.paymentModal-input-img {
    position: relative;
    width: 100%;
}

.paymentModal-master {
    position: absolute;
    top: 30%;
    left: 2%;
}

.paymentModal-input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #292929;
    border-radius: 3px;
    width: 100%;
    margin-top: 10px;
    padding: 1.5rem;
    padding-left: 4rem;
    font: normal normal normal 13px Josefin Sans;

    &::placeholder {

        font: normal normal normal 13px Josefin Sans;
        letter-spacing: 0px;
        color: #CECBC8;
    }
}

.paymentModal-input2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECBC8;
    border-radius: 3px;
    width: 100%;
    margin-top: 10px;
    padding: 1.5rem;

    font: normal normal normal 13px Josefin Sans;

    &::placeholder {

        font: normal normal normal 13px Josefin Sans;
        letter-spacing: 0px;
        color: #CECBC8;
    }
}

.paymentModal-check-card {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 10px;
}

.paymentModal-check {
    margin-top: 20px;
}

.paymentModal-cancel-add {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-top: 30px;
}

.paymentModal-cancel {
    background: #FAF9F7 0% 0% no-repeat padding-box;
    border-radius: 3px;
    text-align: center;
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #798F7D;
    padding: 1rem 1.5rem;
    margin-right: 20px;
    cursor: pointer;
}

.paymentModal-add2 {
    background: #798F7D 0% 0% no-repeat padding-box;
    border-radius: 3px;
    text-align: center;
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 1rem 1.5rem;
    cursor: pointer;
}

.paymentModal-cards {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.payment-visa {
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
}

.payment-07 {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    margin-top: 10px;
}

.payment-edit {
    text-decoration: underline;
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    margin-right: 20px;
    cursor: pointer;
}

.payment-delete {
    text-decoration: underline;
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #E23030;
    margin-right: 80px;
    cursor: pointer;
}

.payment-default {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #798F7D;
    margin-left: 10px;
    cursor: pointer;
}
.recover-flower-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 250px;
    height: 500px;
}

.recover-flower-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.recover-wrapper {
    position: relative;
    width: 100%;
    // height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}
.recover-link{
    color: #292929 !important;
}
.recover-error-message{
    text-align: center;
    color: red;
    margin-top: 15px;
}
.recover-success-message{
    text-align: center;
  
    margin-top: 15px;
}
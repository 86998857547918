.faq-flower-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 350px;
    height: 200px;
}
.faq-flower-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.faq-contact {
    font: normal normal normal 22px Josefin Sans;
    color: #292929;
    margin-top: 100px;
}
.email {
    background: linear-gradient(180deg, #FFF9EC 0%, #FFFFFF 100%);
    padding: 50px 0;
}

.email-container {
    display: flex;
    flex-direction: column;

    align-items: center;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.email-flower{
    position: absolute;
    right: 0;
    bottom: 220px;
    height: 250px;
    width: 250px;
  
}
.email-logo {
    width: 130px;
    height: 120px;
   
    object-fit: contain;
}

.email-croc {
    width: 100%;
    height: 200px;

}

.email-sherry {
    font-size: 30px;
    font-weight: 500;
    margin-top: 50px;

    align-self: flex-start
}

.email-sorry {

    @extend .email-few;

}

.email-few {
    margin-top: 50px;
    font-size: 20px;
    font-weight: 400;
    align-self: flex-start
}

.email-thanks {


    @extend .email-few;

}

.email-best {
    @extend .email-few;

    p {
        color: #B4804D;

    }
}

.email-line {
    width: 100%;
    border-top: 1px solid #D9D9D9;
}

.email-social {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 20px 0;
}

.email-insta {
    width: 45px;
    height: 45px;
    object-fit: contain;
}

.email-copy {
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    margin-top: 20px;
}

.email-you {
    @extend .email-copy;
    margin-top: 40px;
    width: 80%;
}

.email-privacy {
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    text-decoration: underline;
color:#333333 ;
}


.email-circle {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #F7E6C4;
    margin: 0 5px;
}
.email-invoice{
    font-family: Josefin Sans;
font-size: 32px;
font-weight: 700;
line-height: 32px;
text-align: left;
color: #798F7D;

}
.email-inv-voice{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.email-inv-24{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
}
.email-pay-boston{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: end;
}
.email-sherif-pay{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 50px;
}
.email-sherif-sheraton{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: auto;
}
.email-table{
    width: 100%;
    border-bottom: 1px solid #000000;
    margin-top: 100px;
    border-collapse: separate;
    th,td{
        padding: 20px 0;
    }
}
.email-sub-amount{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 100px;
}
.email-th{
font-size: 20px;
font-weight: 700;
line-height: 14px;

}
.email-th-item{
    padding-right: 70px !important;
}
.email-th-amount{
    text-align: end;
}
.email-td-amount{
    @extend .email-th-amount;
    padding-right: 25px !important;
}
.email-th-qty{
    text-align: center;
}
.email-td-qty{
    @extend .email-th-qty;
}
.email-sub{
font-size: 18px;
font-weight: 700;
line-height: 12px;
text-align: left;

}
.email-amount{
    color: #B4804D;
    font-size: 16px;
    font-weight: 700;
    line-height: 12px;
    text-align: center;
    padding-right: 25px;
}
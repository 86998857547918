$width: 662px;
$width2: 1050px;

@media (min-width: #{$width}) and (max-width: #{$width2}) {

    .shop-wrapper-right{
        max-width: 55vw;
    }
    .shop-wrapper-left-right{
        justify-content: center;
    }
    // .shop-video{
    //     margin-right: 10px;
    // }
}


@media (max-width: #{$width}) {
    .shop-wrapper-right{
        max-width: 500px;
    }
    .shop-video{
        height: 160px;
        // margin-left: 0;
        // max-width: 100%;
    }
    .video-vis{
        display: block;
    }
    .shop-arrow{
        margin-left: 0;
    }
    .address{
        margin-bottom: 80px !important;
        margin-top: 80px !important;
    }
    .shop-wrapper{
        margin-top: 0px;
        padding: 0;
    }

    .shop-necklace{
        text-align: center;
    }
    .fav-items-sort{
        justify-content: center !important;
        display: none !important;
    }
    .fav-sort-default{
        display: none !important; 
    }
    .fav-details-images{
        // justify-content: space-around !important;
        // margin: 0px 20px;
        .fav-menu-cont1{
            width: 100%;
            height: 280px;
            overflow: hidden;
        }
        .home-golden{
            font-size: 12px;
        }
    }
    .p-paginator{
        margin-top: 20px;
    }
    .shop-main-img{
        height: 160px;
    }
    .filter-icon {
        display: block;
        align-items: center;
        // width: 100%;
       
    }
    .filter-res{
        background-color: #FAF9F7;
        color: #B4804D;
        width: 100%;
        display: flex;
        align-items: center;
         justify-content: center;
    }

    .shop-wrapper-left{
        margin: 5px 10px;
        display: none;
        width: 100%;
    }
    .filter-visible{
        padding: 1rem 2rem;
    }
  
    .shop-wrapper-left-right.filter-visible .shop-wrapper-left {
        display: block;
    }

    .shop-wrapper-left-right.filter-visible .shop-wrapper-right {
        display: none;
    }
    .filter-header{
        display: flex;
        justify-content: space-between;
    }
    .img-close{
        display: block;
        margin: 0px 10px;

    }
    .grey_nav3{
        padding-left: 10px;
        padding-bottom: 10px;
    }

    .seprator_res{
        background-color: #E1CBB5;
        width: 1px;
        height: 40px;
    }
    // .grey_nav{display: none;}
    // .grey_nav_cate{
    
    //     // display: block;
    // }
}   
$width: 662px;


@media (max-width: #{$width}) {

    .collections-discover{
        padding:0 5rem;
        line-height: 1.3 !important;
    }
    .designers-img{
        width: 150px !important;
        height: 150px !important;
    }
    .designers-imgs {
    flex-wrap: wrap;
    justify-content: space-around !important;
    margin-top: 20px !important;
    gap: 0 !important;
    }
    .designer-img-text{
        margin-bottom: 20px;
    }
}
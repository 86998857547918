$width: 662px;

$width2: 1000px;

@media (min-width: #{$width}) and (max-width: #{$width2}) {}

@media (max-width: #{$width}) {
    #fav-sort-default {
        display: flex !important;
        width: 100%;
    }

    .fav-menu-col {
        gap: 25px;
    }

    #fav-items-sort {
        justify-content: space-between !important;
    }

    .fav-love {
        top: 5%;
        width: 25px !important;
        height: 25px !important;
    }

    #home-love {
        top: 3%;
    }

    .fav-love2 {
        top: 15%;
        width: 25px !important;
        height: 25px !important;
    }

    #fav_wrapper {
        padding: 0 1rem;
    }

    #fav-swiper-img-1 {
        width: 100%;
        height: 120px;
        margin-top: 40px;
    }

    .fav-details-images {
        gap: 15px;
    }

    .fav-4-items {
        display: none;
    }

    #fav-window {
        margin-left: auto;
    }

    #fav-details-images .fav-menu-cont1-col {
        width: 300px;
        height: 150px;
    }

    .fav-menu {
        margin-left: 4vw;
    }

    .details-link-window {
        margin-left: 0;
        margin-right: 0;
        right: auto;
        font-size: 12px;
    }

    .fav-love,
    .fav-love2,
    .fav-love-end,
    .fav-love2-end {
        display: block;
    }

    // .home-golden,
    // .home-price {
    //     // display: none;
    // }
    .fav-menu-col {
        width: 100%;
        margin-left: 0;
    }

    .fav-golden-col {
        margin-top: 15px;
        width: 70%;
    }

    .details-wiper-img-1-col {
        margin-top: 15px !important;
        height: 80px !important;
        width: 80% !important;
    }

    #fav-details-images {
        padding: 0;
    }
}
$width: 662px;

$width2: 1000px;

@media (min-width: #{$width}) and (max-width: #{$width2}) {
    .header-wrapper {
        width: 100%;
    }

    .header-shop {
        font-size: 12px;
    }

    .header-logo {
        width: 50px;
        height: 40px;
    }

    .home-wrapper-3 {
        padding: 0 2rem;
    }
    .header-brand-section{
        margin-right: 10px;
    }
}

@media (max-width: #{$width}) {

    // .header-nav,#header-love-image,#header-search-image{
    //     display: none;
    // }
    // .header-wrapper{
    //     justify-content: center;
    // }
    .header-wrapper {
        display: none;
    }

    .header-wrapper-resp {
        display: flex;
        align-items: center;
    }

    .header-logo {
        margin: 0 auto;
        width: 50px;
        height: 40px;
    }

    #header-profile {
        margin-right: 15px;
    }

    .header-search-recents {
        flex-direction: column;
        gap: 10px;
        height: 180px;
        overflow-y: scroll;
    }

    .header-logos {
        position: absolute;
        bottom: 5%;
        margin-left: auto;
        margin-right: auto;

    }

    .header-search-logo {
        width: 50px;
        height: 50px;
        z-index: 999;
    }

    .header-search-bird {
        width: 30px;
        height: 40px;
    }

    .header-search-close-img2 {
        top: 5%;
        width: 15px;
        height: 15px;
        right: 5%;
    }

    .header-search-cont {
        padding: 0 1rem;
    }

    .header-search-cont-img {
        margin-top: 50px;
    }

    .header-abs-search {
        display: block;
        position: absolute;
        top: 5%;
        margin-left: auto;
        margin-right: auto;
    }

    .hamburger-react div div {
        height: 2px !important;

        width: 20px !important;
    }

    .hamburger-react {
        height: 40px !important;
    }


    .header-menu-recent {
        font: normal normal 400 15px Josefin Sans;
        letter-spacing: 0px;
        display: flex;
        margin: 20px 0;
        color: black;
        align-self: flex-start;
        text-decoration: none;
        position: relative;
    }

    #header-menu-cont {
        padding: 0 2rem;
        overflow: auto;
    }

    .header-menu-recents {
        margin-top: 50px;
        align-self: flex-start;
    }

    #header-support {
        color: black;
        font: normal normal 400 15px Josefin Sans;
        position: relative;
    }

    #header-up,
    #header-down {
        fill: black;
        width: 15px;
        height: 15px;
    }

    #header-acc .css-1086bdv-MuiPaper-root-MuiAccordion-root,
    #header-acc .css-1aj41gs {
        border: 0;
        margin-top: 0;
        padding-bottom: 0;
    }

    #header-acc .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root,
    #header-acc .css-1oqimao {
        display: inline-flex;
    }

    .header-boston {
        text-align: left;
        font: normal normal 300 15px Josefin Sans;
        letter-spacing: 0px;
        color: black;
        margin-bottom: 15px;
        text-decoration: none;
    }

    #menu-search {
        height: 100vh;
    }

    .header-menu-orange {
        color: #F1C376;
    }
    .header-menu-orange-ex {
        color: #F1C376;
        .header-orange-left-line-ex{
            position: absolute;
            left: -10px;
            height: 130%;
            top: -5px;
            border: 1.5px solid #F1C376;
            border-radius: 5px;
        }
    }
    .header-orange-left-line {
        position: absolute;
        left: -10px;
        height: 130%;
        top: -5px;
        border: 1.5px solid #F1C376;
        border-radius: 5px;
    }
    .test-insta .HeaderCta{
        display: none !important;
    }
    .test-insta a.ViewProfileButton, a.FollowButton{
        display: none !important;
    }
}
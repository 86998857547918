$width: 662px;


@media (max-width: #{$width}) {


    .login-input2 {
        width: 100% !important;
    }

    .register-bird {
        width: 70px;
    }
    .register-register-bird{
        margin-top: 0;
    }
    .register-heart1{
        bottom: 15%;
        position: absolute;
        left: 5%;
        width: 70px;
        top: revert;
    }
    .register-heart2{
        bottom: 22%;
        position: absolute;
        right: 15%;
        width: 30px;
        right: 8%;
    }
}
$width: 662px;


@media (max-width: #{$width}) {

    #return-contact-form{
        display: none;
    }
.return-rquest-contact-details{
    display: block;
    text-align: center;
}
}
.privacy {
    width: 100vw;
    margin-top: 140px;
    padding-bottom: 300px;
    position: relative;
}

.privacy-wrapper {
    position: relative;
    width: 100%;
    // height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;

    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.privacy-privacy {
    font: normal normal 600 35px Josefin Sans;
    color: #292929;
    text-transform: uppercase;
    align-self: center;
    position: relative;
    margin-top: 100px;
}

.privacy-the {
    font: normal normal normal 13px Josefin Sans;
    color: #929292;
    margin-top: 70px;
    line-height: 1.4;
}

.privacy-the2 {
    font: normal normal normal 13px Josefin Sans;
    color: #929292;
    margin-top: 30px;
    line-height: 1.4;

    span {
        font: normal normal normal 13px Josefin Sans;
        letter-spacing: 0px;
        color: #292929;
    }

    p {
        margin: 0
    }

    ;
}

.privacy-contact {
    font: normal normal normal 22px Josefin Sans;
    color: #292929;
    margin-top: 30px;
}

.privacy-contact2 {
    font: normal normal normal 12px Josefin Sans;
    color: #292929;
    margin-top: 30px;
    text-decoration: underline;
}

.privacy-contact3 {
    font: normal normal normal 22px Josefin Sans;
    color: #292929;
}

.privacy-the3 {
    font: normal normal normal 13px Josefin Sans;
    color: #929292;
    line-height: 1.4;
}


.css-1086bdv-MuiPaper-root-MuiAccordion-root ,.css-1aj41gs{
    box-shadow: none !important;
    border-bottom: 1px solid #CECBC8;
    margin-top: 30px;
    padding-bottom: 30px;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root::before ,.css-1aj41gs::before{
    display: none !important;
}

.FAQ-accordion-plus {
    width: 30px;
    height: 30px;
}

.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root ,.css-1oqimao{
    padding: 0 !important;
}

// .css-1086bdv-MuiPaper-root-MuiAccordion-root{
//     margin-top: 30px;
// }
.css-15v22id-MuiAccordionDetails-root {
    padding: 0 !important;
}
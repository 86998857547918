.checkout {
    width: 100vw;
    height: calc(100vh - 150px) ;
    margin-top: 140px;
    // margin-bottom: 200px;
}

.checkout-wrapper-1 {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.checkout-left-right {
    display: flex;
    gap: 50px;
    height: 100%;
    margin-left: auto;
}

.checkout-left {
    width: 550px;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 50px;
}

.checkout-right {
    width: 750px;
    height: calc(100vh - 100px);
    background: #FAF9F7 0% 0% no-repeat padding-box;
    padding: 2.5rem;
    position: relative;
}

.checkout-img-name-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

}

.checkout-img {
    position: relative;
    width: 40px;
    height: 40px;
    background: #ECECEC 0% 0% no-repeat padding-box;
    border: 1px solid #929292;
    border-radius: 3px;
    margin-right: 15px;
}

.product-qty {
    position: absolute;
    top: -10px;
    right: -8px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #292929;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal normal 10px/24px Josefin Sans;
}

.checkout-img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.checkout-golden {
    font: normal normal 600 12px Josefin Sans;
    color: #292929;
}

.checkout-input-apply {
    display: flex;
}

.checkout-input {
    width: 100%;
    border: none;
    padding: .5rem;
    border: 1px solid #CECBC8;
    border-radius: 3px;

    &::placeholder {
        font: normal normal normal 12px Josefin Sans;
        letter-spacing: 0px;
        color: #CECBC8;
        padding-left: 10px;
    }
}

.checkout-apply {
    background: #292929 0% 0% no-repeat padding-box;
    border-radius: 3px;
    padding: 1rem;
    color: #fff;
    margin-left: 20px;
}

.checkout-subtotal-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    margin-top: 10px;
}

.checkout-total {
    font: normal normal 600 20px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
}

.checkout-price {
    font: normal normal 600 20px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;

    span {
        font: normal normal 600 12px Josefin Sans;
        letter-spacing: 0px;
        color: #929292;
    }
}

.checkout-express {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    margin-top: 50px;
    margin-bottom: 10px;
}

.checkout-payments {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.checkout-pay-img {
    width: 150px;
    height: 40px;
}

.checkout-pay-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.checkou-or {
    margin-top: 20px;
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
}

.checkout-contact-login {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.checkout-contact {
    font-size: 20px;
    letter-spacing: 0px;
    font-weight: 600;
    color: #292929;
    display: flex;
    align-self: flex-start;
    align-items: center;
}

.checkout-contact2 {
    font-size: 20px;
    letter-spacing: 0px;
    font-weight: 600;
    color: #292929;
    display: flex;
    align-self: flex-start;
    margin-top: 20px;
    align-items: center;
}

.checkout-login {
    text-decoration: underline;
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
}

.checkout-input2 {
    width: 100%;
    border: 1px solid #CECBC8;
    padding: .5rem;
    margin-top: 15px;

    &::placeholder {
        color: #CECBC8;
    }
}

.checkout-email {
    display: flex;
    align-self: flex-start;
    width: 100%;
    gap: 10px;
    align-items: center;
    margin-top: 15px;
}

.checkout-add {
    text-decoration: underline;
    letter-spacing: NaNpx;
    color: #292929;
    font: normal normal normal 15px Josefin Sans;
    margin-top: 20px;
    display: flex;
    align-self: flex-start;
}

.checkout-shipment-cont {
    border-radius: 3px;
    width: 100%;
    padding: 1rem;
    background: #FEF9F5 0% 0% no-repeat padding-box;
    // border-bottom: 1px solid #E1CBB5;
}

.checkout-payment-container {
    background: #FEF9F5 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #E1CBB5;
    border-top: 1px solid #E1CBB5;
}

.checout-dot-img {
    width: 15px;
    height: 15px;
    object-fit: contain;
    margin-right: 10px;
}

.checkout-dhl {
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: #B4804D;

}

.checkout-20 {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #B4804D;
    margin-right: 20px;
}

.checkout-sunday {
    margin-left: 25px;
    margin-top: 10px;
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
}

.checkout-shipment-cont2 {
    width: 100%;
    padding: 1rem;
}

.checkout-menu {
    margin-right: 10px;
    width: 120px;
    height: 120px;
    background: #FAF9F7 0% 0% no-repeat padding-box;
    position: relative;
    // border: 2px solid #B4804D;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.checkout-rebel {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 4.2px;
    color: #B4804D;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
}

.checkout-golden {
    font: normal normal 600 14px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    // margin-top: 15px;
}

.checkout-color {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #929292;
    margin-top: 10px;
}

.checkout-swiper-img-1 {
    width: 120px;
    height: 120px;
    cursor: pointer;
}

.checkout-swiper-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.checkout-shipment-cont3 {
    border: 1px solid #E1CBB5;
    width: 100%;
}

.checkout-shipment {
    font: normal normal 600 12px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-top: 20px;
    margin-left: 15px;
    display: flex;
    align-self: flex-start;
    margin-bottom: 20px;
}

.checkout-shipment-cont4 {
    background-color: #FAF9F7;
    padding: 1rem;
    border-bottom: 1px solid #CECBC8;
    width: 100%;
}

.checkout-shipment-cont5 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.checkout-shipment-cont6 {
    border: 1px solid #CECBC8;
    border-radius: 3px;
    width: 100%;
}

.checkout-pay {
    background: #798F7D 0% 0% no-repeat padding-box;
    border-radius: 3px;
    padding: .75rem;
    color: #fff;
    width: 100%;
    text-align: center;
}

.checkout-refund {
    text-decoration: underline;
    padding-bottom: 5px;
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
}

.checkout-use {
    font: normal normal normal 13px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
}

.checkout-check-save {
    border: 1px solid #CECBC8;
    border-radius: 3px;
    padding: .75rem;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}
.checkout-cards{
    display: flex;
}
.checkout-save {
    font: normal normal normal 13px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-left: 15px;
}

.checkout-right-cont {
    max-width: 600px;
}

.checkout-flower {
    position: absolute;
    bottom: 10%;
    right: 20%;
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.checkout-after {
    text-align: center;
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    line-height: 1.3;
}

.checkout-3 {

    display: flex;
    align-items: center;
    justify-content: center;
    color: #292929;
    font: normal normal normal 12px Josefin Sans;
    border: 0.5px solid #98A8B8;
    border-radius: 3px;
    padding: .5rem .75rem;
}

.checkout-pencil {
    width: 15px;
    height: 15px;
    object-fit: contain;
    margin-right: 10px;
}

.order-list {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 20px;
    // padding-right: 15px;
    margin-bottom: 15px;
    padding-top: 15px;
    padding-right: 10px;
}

.countrey-state {
    display: flex;

}

.checkout-form {
    margin-left: auto;
    margin-right: auto;
    padding: 0 2rem;
}

.checkout-normal {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-left: 5px;
}

.checkout-express2 {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #292929;
    margin-left: 5px;
}

.checkout-noraml-cont {
    background: #292929 0% 0% no-repeat padding-box;
    border: 1px solid #E1CBB5;
    padding: .5rem;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.checkout-noraml-cont2 {
    border: 1px solid #E1CBB5;
    border-radius: 3px;
    padding: .5rem;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.checkout-golden-golden2 {

    display: flex;
    align-items: center;
    width: 100%;
}

#checkout-golden {
    margin-left: auto;
}

#checkout-golden2 {
    width: 200px;
}

.error-msg2 {
    font: normal normal normal 15px Josefin Sans;
    letter-spacing: 0px;
    color: red;
    text-align: start;
    width: 100%;
    // margin-left: auto;
    // margin-right: auto;
    margin-top: 5px;
    margin-left: 5px;
    // margin-top: 30px;
    // text-align: center;
}
.error-msg3 {
    font: normal normal normal 12px Josefin Sans;
    letter-spacing: 0px;
    color: #B4804D;
    text-align: start;
    width: 100%;
    // margin-left: auto;
    // margin-right: auto;
    margin-top: 5px;
    margin-left: 5px;

    // margin-top: 30px;
    // text-align: center;
}
.payment-web {
    display: block;
    width: 100%;
    margin: 10px 0px;
}

.payment-mob {
    display: none;
}

.checkout-cart-products {
    width: 100%;
}

.checkout-cart-item {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.checkout-product-quan {
    width: 20px;
}

.checkout-golden-golden {
    display: flex;
    justify-content: space-between;
}

// .checkout-contact2 .accordion-item {
//     background-color: transparent;
//     border: none;
//     .accordion-header{
//         button{
//             .brand-header-acc{
//                 width: 100%;
//             }
//     }

//     }
// }
// .accordion-header button{
//     width: 100%;
// }
// .checkout-contact2 .accordion-button {
//     background-color: transparent !important;
//     border: none !important;
// }


.custom-accordion-header {
    width: 100%;
    display: flex;
    // flex-direction: column;
    align-items: start;
    justify-content: space-between;
}

.accordion-button:not(.collapsed) {
    background-color: #FEF9F5 !important;
    box-shadow: none !important;
}

.accordion-button {
    background-color: #FEF9F5 !important;
    position: relative;
}

.accordion-button::after {
    position: absolute;
    /* Set position to absolute */
    top: 10px;
    /* Adjust top position as needed */
    right: 15px;
    /* Adjust right position to align it correctly */
    transform: none;
    /* Ensure no unwanted transformations */
    transition: none;
    /* Optional: Disable transitions if needed */
    color: #B4804D !important;
}

/* Ensure the header container takes the full width and has no padding or margin */
.custom-accordion-header .checkout-shipment-cont {
    width: 100%;
    padding: 0;
    margin: 0;
}

/* Style adjustments for other elements if needed */
.brand-header-acc {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout-dhl {
    flex: 1;
}

.mob-input {
    margin-right: 10px;
    // margin-bottom: 15px;
}
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
}
.loading-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 99999999999999999999;
}
.checkout-video{
    z-index: 9999;
    width: 200px;
    height: 200px;
}
.checkout-video2{
    z-index: 9999;
    width: 50px;
    height: 50px;
}
@keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .dots-loading {
    display: inline-block;
  }
  
  .dots-loading img {
    font-size: 18px;
    margin: 0 2px;
    display: inline-block;
    animation: blink 1.4s infinite both;
    width: 15px;
    height: 15px;
    object-fit: contain;
  }
  
  .dots-loading img:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dots-loading img:nth-child(3) {
    animation-delay: 0.4s;
  }
  
$width: 662px;

$width2: 1050px;

@media (min-width: #{$width}) and (max-width: #{$width2}) {

    .checkout-right {
        width: 500px;
    }

    .checkout-left-right {
        gap: 0;
    }

    .checkout-shipment-cont2 {
        padding: 1rem 0;
    }

    .checkout-left {
        padding-right: 0;
    }
    .header-added-img2{
        bottom: 4% !important;
    }
}

@media (max-width: #{$width}) {
    .checkout {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        margin-top: 80px;
    }

    .checkout-express {
        font-size: 16px;
    }

    .header-added-img2 {
        display: none;
    }

    .checkout-payments {
        flex-direction: column;

        // width: 319px;
        .checkout-pay-img {
            width: 100%;
        }
    }

    // .checkout-golden-golden{
    //     justify-content: flex-start;
    //     gap: 30px;
    // }
    .checkout-golden-golden2 {

        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: auto;
    }

    #checkout-golden {
        margin-left: 0;
    }

    .checkout-left-right {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px auto;

        .checkout-left {
            //  width: 90%;
            margin: auto;
            height: auto;

            .checkout-email {
                flex-direction: column;
            }

            .checkout-email-mob {
                flex-direction: row;
            }
        }
    }

    .accordion-body {
        padding: 0px !important;
    }

    .checkout-menu {
        width: 102px;
        height: 106px;

    }

    .checkout-rebel {
        font-size: 10px;

    }

    .payment-web {
        display: none;
    }

    .payment-mob {
        display: block;
    }

    .checkout-right {
        height: auto;
    }

    .checkout-cards {
        flex-direction: column;
    }

    .checkout-refund {
        text-decoration: none;
        padding-bottom: 5px;
    }

    #checkout-golden2 {
        width: 70px;
    }

    .checkout-email-mob {
        display: flex;
        align-items: center;
    }

    .countrey-state {
        flex-direction: column;

    }

    .mob-input {
        margin-right: 0px;
        margin-bottom: 15px;
    }

}
$width: 662px;



@media (max-width: #{$width}) {
    .terms{
        margin-top: 100px;
    }
    .terms-privacy{
        font-size: 25px;
    }
    .terms-contact {
        font-size: 18px;
    }
}